import { Data } from '@angular/router';

export const INACTIVE_ACCOUNT: Data = {
  title: 'Account not activated',
  message: 'Sorry, it seems that you do not have an active account in Omnia.',
};

export const INVALID_ACCOUNT: Data = {
  title: 'No contracts found',
  message: 'Sorry, it seems that no active contracts are associated with your account',
};
