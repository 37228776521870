import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  private readonly _showLoader = new BehaviorSubject<boolean>(false);

  public showLoader$ = this._showLoader.asObservable();

  public show(): void {
    this._showLoader.next(true);
  }

  public hide(): void {
    this._showLoader.next(false);
  }
}
