import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';

import {
  catchError,
  combineLatest,
  EMPTY,
  filter,
  map,
  mergeMap,
  Observable,
  take,
  tap,
} from 'rxjs';

import { OmniaAuthService } from '@services/authentication/omnia-auth.service';
import { PricemonitorAuthService } from '@services/authentication/pricemonitor-auth.service';
import { UserTokenService } from '@services/authentication/user-token.service';

import { AppState } from '@store/app.state';
import { activeCompanyAction, companiesAction, userDetailsAction } from '@store/user';

import { portalActions } from '../portals/portals.actions';

import {
  authAction,
  omniaUserTokenAction,
  tokenRenewalAction,
  userTokenAction,
} from './authentication.actions';

@Injectable()
export class AuthenticationEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<AppState>,
    private readonly omniaAuthService: OmniaAuthService,
    private readonly pricemonitorAuthService: PricemonitorAuthService,
    private readonly userTokenService: UserTokenService
  ) {}

  public readonly initLogin$ = createEffect(() => this.initLogin());

  public readonly handleLoginSuccess$ = createEffect(() => this.handleLoginSuccess());

  public readonly handleLoginFailure$ = createEffect(() => this.handleLoginFailure());

  public readonly initRenewToken$ = createEffect(() => this.initRenewToken(), {
    dispatch: false,
  });

  private initLogin(): Observable<Action> {
    return this.actions$.pipe(
      ofType(authAction.init),
      mergeMap(() => this._getToken()),
      mergeMap(() => this._getOmniaToken()),
      map(() => userDetailsAction.init())
    );
  }

  private handleLoginSuccess(): Observable<Action> {
    return combineLatest([
      this.actions$.pipe(ofType(portalActions.success)),
      this.actions$.pipe(ofType(activeCompanyAction.success)),
      this.actions$.pipe(ofType(userDetailsAction.success)),
    ]).pipe(map(() => authAction.success()));
  }

  private handleLoginFailure(): Observable<Action> {
    return this.actions$.pipe(
      ofType(
        portalActions.failed,
        companiesAction.failed,
        userDetailsAction.failed,
        userTokenAction.failed,
        omniaUserTokenAction.failed
      ),
      map(() => authAction.failed())
    );
  }

  private initRenewToken(): Observable<Action> {
    return this.actions$.pipe(
      ofType(tokenRenewalAction.init),
      mergeMap(() => this._getNewToken()),
      mergeMap(() => this._getNewOmniaToken()),
      map(() => tokenRenewalAction.success())
    );
  }

  private _getToken(): Observable<string> {
    return this.pricemonitorAuthService.getUserToken().pipe(
      take(1),
      filter((token) => !!token),
      tap((token) => {
        this.userTokenService.userToken = token;
        this.store.dispatch(userTokenAction.success({ token }));
      }),
      catchError((error: HttpErrorResponse) => {
        this.store.dispatch(userTokenAction.failed({ error }));

        return EMPTY;
      })
    );
  }

  private _getNewToken(): Observable<string> {
    return this.pricemonitorAuthService.getUserToken().pipe(
      take(1),
      filter((token) => !!token),
      tap((token) => {
        this.userTokenService.userToken = token;
        this.store.dispatch(userTokenAction.success({ token }));
      }),
      catchError((error: HttpErrorResponse) => {
        this.store.dispatch(userTokenAction.failed(error));
        this.store.dispatch(tokenRenewalAction.failed());

        return EMPTY;
      })
    );
  }

  private _getOmniaToken(): Observable<string> {
    return this.omniaAuthService.getUserToken().pipe(
      take(1),
      filter((omniaToken) => !!omniaToken),
      tap((omniaToken) => {
        this.store.dispatch(omniaUserTokenAction.success({ omniaToken }));
      }),
      catchError((error: HttpErrorResponse) => {
        this.store.dispatch(omniaUserTokenAction.failed({ error }));

        return EMPTY;
      })
    );
  }

  private _getNewOmniaToken(): Observable<string> {
    return this.omniaAuthService.getUserToken().pipe(
      take(1),
      filter((omniaToken) => !!omniaToken),
      tap((omniaToken) => {
        this.store.dispatch(omniaUserTokenAction.success({ omniaToken }));
      }),
      catchError((error: HttpErrorResponse) => {
        this.store.dispatch(omniaUserTokenAction.failed(error));
        this.store.dispatch(tokenRenewalAction.failed());

        return EMPTY;
      })
    );
  }
}
