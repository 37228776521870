import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map, Observable } from 'rxjs';

import { environment } from '@environments/environment';

import { Shop, ShopStatus } from '@models/interfaces/shops.interface';

import { objectKeysToLowerCase } from '@utils/helpers/object-to-lowercase/object-keys-to-lowercase';

import { IShopsService } from './shops.interface.service';

@Injectable({
  providedIn: 'root',
})
export class ShopsService implements IShopsService {
  private readonly headers = new HttpHeaders({
    'Content-Type': 'application/x-www-form-urlencoded',
  });

  constructor(private readonly http: HttpClient) {}

  public getShops(): Observable<Shop[]> {
    return this.http.post<Shop[]>(
      environment.bffLegacyEndpoint + '/feedmanager/Config/GetShops',
      {}
    );
  }

  public getShopStatus(shopName: string): Observable<ShopStatus> {
    return this.http
      .post(
        environment.bffLegacyEndpoint + '/feedmanager/Feed/get_connect_shop_status',
        this.provideShopStatusBody(shopName),
        { headers: this.headers }
      )
      .pipe(map((response) => objectKeysToLowerCase(response) as ShopStatus));
  }

  public runShop(shopName: string): Observable<boolean> {
    return this.http.post<boolean>(
      environment.bffLegacyEndpoint + '/feedmanager/Feed/run_shop',
      this.provideShopStatusBody(shopName),
      {
        headers: this.headers,
      }
    );
  }

  public cancelShop(shopName: string): Observable<boolean> {
    return this.http.post<boolean>(
      environment.bffLegacyEndpoint + '/feedmanager/Feed/cancel_shop',
      this.provideShopStatusBody(shopName),
      { headers: this.headers }
    );
  }

  private provideShopStatusBody(shopName: string): string {
    return ['shopName', JSON.stringify(shopName)].map(encodeURIComponent).join('=');
  }
}
