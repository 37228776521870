import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { LookerService } from '@Patagona/pricemonitor-internal-typescript-angular-13';

import { catchError, EMPTY, map, mergeMap, Observable, of, withLatestFrom } from 'rxjs';

import { AppInsightsService } from '@app/tracing/application-insights/app-insights.service';
import { getCurrentSid } from '@app/utils/helpers/get-current-sid';

import { AppState } from '@store/app.state';

import { selectUserId } from '../details/details.selectors';
import { selectActiveContract } from '../preferences/preferences.selectors';

import { getLookerAttributesAction } from './looker.actions';
@Injectable()
export class LookerEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly lookerService: LookerService,
    private readonly store: Store<AppState>,
    private readonly appInsightsService: AppInsightsService
  ) {}

  public readonly getLookerAttributes$ = createEffect(() => this.getLookerAttributes());

  public readonly logLookerAttributesToAppInsights$ = createEffect(() =>
    this.logLookerAttributesToAppInsights()
  );

  private getLookerAttributes(): Observable<Action> {
    return this.actions$.pipe(
      ofType(getLookerAttributesAction.init),
      mergeMap(() =>
        this.lookerService.getLookerUserAttributes().pipe(
          map((response) => {
            const { contractId, contractSid } = response.data;
            return getLookerAttributesAction.success({ contractId, contractSid });
          }),
          catchError((error) => {
            console.error('Error fetching Looker User Attributes', error);
            return of(getLookerAttributesAction.failed({ error }));
          })
        )
      )
    );
  }

  private logLookerAttributesToAppInsights(): Observable<Action> {
    return this.actions$.pipe(
      ofType(getLookerAttributesAction.success),
      withLatestFrom(this.store.select(selectUserId), this.store.select(selectActiveContract)),
      mergeMap(([{ contractId, contractSid }, userId, activeContract]) => {
        const currentSid = getCurrentSid(window.location);
        const traceFields = {
          looker_contractId: contractId,
          looker_contractSid: contractSid,
          browser_contractSid: currentSid,
          broswer_url: window.location.href,
          userId,
          in_app_contract_mismatch_detected: activeContract?.sid !== currentSid,
        };
        this.appInsightsService.logEvent('LOOKER Dashboard run complete', traceFields);
        return EMPTY;
      })
    );
  }
}
