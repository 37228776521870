// During migrating all 1.0 endpoints to go through PM API, we need to identify the endpoints that are migrated and still not represened in the api client library.
// Temporarily added for non-client PM API calls till we use the api client

export const isNonClientPMApiCall = (url: string): boolean =>
  url.includes('/export/Report/switchEnabled') ||
  url.includes('/formula/calculate') ||
  url.includes('mappings') ||
  url.includes('availableFields') ||
  url.includes('filters') ||
  url.endsWith('feeds');
