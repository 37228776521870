import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from '@environments/environment';

import { OrganizationsPortal } from '@models/interfaces/organization.interface';
import { PortalInterface } from '@models/interfaces/portals.interface';

import { IPortalService } from './portal.interface.service';

@Injectable({
  providedIn: 'root',
})
export class PortalService implements IPortalService {
  constructor(private readonly http: HttpClient) {}

  public getOrganizationAndPortals(): Observable<OrganizationsPortal> {
    return this.http.get<OrganizationsPortal>(
      environment.bffAbsEndpoint + '/user/organizationsPortals'
    );
  }

  public getPortals(): Observable<PortalInterface[]> {
    return this.http.get<PortalInterface[]>(environment.bffAbsEndpoint + '/user/portals');
  }

  public switchPortal(portalName: string): Observable<string> {
    return this.http.get<string>(environment.bffAbsEndpoint + '/portal/switch/' + portalName);
  }
}
