import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';

import { environment } from '@environments/environment';

const { domain, clientId, audience, redirectUri } = environment.features.singleSignOn.properties;

@NgModule({
  imports: [
    AuthModule.forRoot({
      domain,
      clientId,
      authorizationParams: {
        audience,
        redirect_uri: redirectUri,
      },
    }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },
  ],
})
export class Auth0Module {}
