import { createSelector } from '@ngrx/store';

import { AppState } from '@store/app.state';

import { ContractsState } from './contracts.reducers';

const selectContractsKey = ({ user }: AppState) => user.contracts;

export const selectContractSettings = createSelector(
  selectContractsKey,
  ({ settings }: ContractsState) => settings
);
