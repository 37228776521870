import { ApiActions } from '@store/api-actions';

import { PortalState } from './portals.reducers';

export enum PortalActions {
  GET_PORTALS = '[Portals] Get portals ',
}

export const portalActions = new ApiActions<{}, { portals: PortalState }>(
  PortalActions.GET_PORTALS
);
