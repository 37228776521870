import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { Observable, take, tap } from 'rxjs';

import { AppInsightsService } from '@app/tracing/application-insights/app-insights.service';

import { environment } from '@environments/environment';

import { AppState } from '@store/app.state';
import { selectFrontendAPILoggingData } from '@store/user';

@Injectable()
export class LogFeApiRequestsInterceptor implements HttpInterceptor {
  constructor(
    private readonly store: Store<AppState>,
    private readonly appInsightsService: AppInsightsService
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const { url, method } = request;
    const isFEApiRequest =
      request.url.indexOf(environment.bffAbsEndpoint) >= 0 ||
      request.url.indexOf(environment.portalApiBaseUrl) >= 0;

    const isPortalsRequest = url.indexOf('/user/portals') >= 0;
    if (!isFEApiRequest || isPortalsRequest) {
      return next.handle(request);
    }
    this.store
      .select(selectFrontendAPILoggingData)
      .pipe(
        take(3),
        tap(([activePortalName, userId, activePortalCustomerName]) => {
          if (activePortalName && userId && activePortalCustomerName) {
            this.appInsightsService.logEvent('FE_API_REQ', {
              portalName: activePortalName,
              userId,
              requestUrl: url,
              method,
              customerAdpsName: activePortalCustomerName,
            });
          }
        })
      )
      .subscribe();

    return next.handle(request);
  }
}
