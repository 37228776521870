<button
  *ngIf="isRaisedButton"
  mat-raised-button
  class="flex items-center justify-center"
  [disabled]="disabled"
  [color]="color"
  (click)="click($event)"
>
  <ng-container [ngTemplateOutlet]="buttonContent"></ng-container>
</button>

<button
  *ngIf="isOutlinedButton"
  mat-stroked-button
  class="flex items-center justify-center"
  [disabled]="disabled"
  [color]="color"
  (click)="click($event)"
>
  <ng-container [ngTemplateOutlet]="buttonContent"></ng-container>
</button>

<button
  *ngIf="!isRaisedButton && !isOutlinedButton"
  mat-button
  [disabled]="disabled"
  [color]="color"
  class="flex items-center justify-center"
  (click)="click($event)"
>
  <ng-container [ngTemplateOutlet]="buttonContent"></ng-container>
</button>

<ng-template #buttonContent>
  <div
    [ngClass]="{ 'flex-row-reverse': hasIconAfterText }"
    class="flex items-center justify-center"
  >
    <mat-icon class="{{ iconSize }} {{ iconAnimationClass }}" *ngIf="icon">{{ icon }} </mat-icon>
    <span
      *ngIf="text"
      class="leading-mat-button {{ textSize }}"
      [ngClass]="{
        'mx-2': icon || hasIconAfterText,
        'text-white': isRaisedButton && color === 'primary'
      }"
      >{{ text }}</span
    >
  </div>
</ng-template>
