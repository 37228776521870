import { ErrorHandler, NgModule } from '@angular/core';
import { ApplicationinsightsAngularpluginErrorService } from '@microsoft/applicationinsights-angularplugin-js';

import { AppInsightsService } from './application-insights/app-insights.service';

@NgModule({
  providers: [
    AppInsightsService,
    {
      provide: ErrorHandler,
      useClass: ApplicationinsightsAngularpluginErrorService,
    },
  ],
})
export class TracingModule {
  constructor(private readonly appInsightsService: AppInsightsService) {}
}
