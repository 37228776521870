import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

import { Observable, switchMap, take } from 'rxjs';

import { isFeApiCall } from '@app/utils/helpers/is-frontend-api-call';

import { AppState } from '@store/app.state';
import { selectActivePortalName } from '@store/user';

import { SubscriptionManagementBaseClass } from '@utils/subscription-management/subscription-management-base-class';

@Injectable()
export class AddPortalNameHeaderInterceptor
  extends SubscriptionManagementBaseClass
  implements HttpInterceptor
{
  constructor(private readonly store: Store<AppState>, private readonly router: Router) {
    super();
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const needsPortalNameHeader = isFeApiCall(request.url);

    if (needsPortalNameHeader) {
      return this.store.select(selectActivePortalName).pipe(
        take(1),
        switchMap((portalName) => {
          const newRequest = request.clone({
            setHeaders: {
              Portal: portalName?.trim() ?? '',
            },
          });
          return next.handle(newRequest);
        })
      );
    }

    return next.handle(request);
  }
}
