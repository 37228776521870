import { createAction, props } from '@ngrx/store';

import { SnackbarConfig } from '@components/snackbar/snackbar/snackbar.interface';

export enum SnackbarActions {
  SUCCESS = '[Snackbar] Success Update',
  ERROR = '[Snackbar] Error Update',
  DEFAULT = '[Snackbar] Default Update',
  INFO = '[Snackbar] Info Update',
  WARNING = '[Snackbar] Warning Update',
}

export const SnackbarSuccessAction = createAction(SnackbarActions.SUCCESS, props<SnackbarConfig>());
export const SnackbarErrorAction = createAction(SnackbarActions.ERROR, props<SnackbarConfig>());
export const SnackbarDefaultAction = createAction(SnackbarActions.DEFAULT, props<SnackbarConfig>());
export const SnackbarInfoAction = createAction(SnackbarActions.INFO, props<SnackbarConfig>());
export const SnackbarWarningAction = createAction(SnackbarActions.WARNING, props<SnackbarConfig>());
