import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { Observable } from 'rxjs';
import { delay, tap } from 'rxjs/operators';

import { ScrollActions } from './legacy-scroll-element.actions';
import { ScrollElementState } from './legacy-scroll-element.model';

@Injectable()
export class ScrollElementIntoViewEffects {
  public readonly scroll$ = createEffect(() => this.scrollIntoViewSideEffect(), {
    dispatch: false,
  });

  constructor(private readonly actions$: Actions) {}

  private scrollIntoViewSideEffect(): Observable<{ payload: ScrollElementState }> {
    return this.actions$.pipe(
      ofType(ScrollActions.SCROLL_ELEMENT_INTO_VIEW_INIT),
      delay(200),
      tap((res: { payload: ScrollElementState }) => {
        const element = document.querySelector(res.payload.selector);
        const options = res.payload.scrollIntoViewOptions || {
          behavior: 'smooth',
          block: 'center',
          inline: 'center',
        };
        if (element) {
          element.scrollIntoView(options);
        }
      })
    );
  }
}
