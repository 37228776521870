import { createReducer, on } from '@ngrx/store';

import {
  getShopStatus,
  loadShopsAndStatus,
  setGroupDisplayName,
  setGroupId,
} from '@store/product-import';

import { Shop } from '@models/interfaces/shops.interface';

export const productImportFeatureKey = 'productImport';

export interface ProductImportState {
  shop?: Shop;
  groupId?: string;
  groupDisplayName?: string;
}

export const initialProductImportState: ProductImportState = {
  shop: undefined,
  groupId: undefined,
  groupDisplayName: undefined,
};

export const productImportReducer = createReducer(
  initialProductImportState,
  on(loadShopsAndStatus.success, (state, { shop }) => ({
    ...(state || initialProductImportState),
    shop,
  })),
  on(getShopStatus.success, (state, { shop }) => ({
    ...(state || initialProductImportState),
    shop,
  })),
  on(setGroupId, (state, { groupId }) => ({
    ...(state || initialProductImportState),
    groupId,
  })),
  on(setGroupDisplayName, (state, { groupDisplayName }) => ({
    ...(state || initialProductImportState),
    groupDisplayName,
  }))
);
