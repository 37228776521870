import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserTokenService {
  private readonly _userToken = new BehaviorSubject<string>('');

  public readonly userToken$ = this._userToken.asObservable();

  public set userToken(val: string) {
    this._userToken.next(val);
  }
}
