import { createAction } from '@ngrx/store';

import { ApiActions } from '@store/api-actions';

import { Shop } from '@models/interfaces/shops.interface';

enum CommonLegacyActions {
  // Feed Groups actions
  MODULE_INIT = '[Common] Legacy module init',
  MODULE_DESTROY = '[Common] Legacy module destroy',
  COMMON_APIS_CALLS = '[Common] Legacy common API calls ',
}

export const legacyApiCallsAction = new ApiActions<{}, { shops: Shop[] }>(
  CommonLegacyActions.COMMON_APIS_CALLS
);

export const legacyModuleInitAction = createAction(CommonLegacyActions.MODULE_INIT);
export const legacyModuleDestroyAction = createAction(CommonLegacyActions.MODULE_DESTROY);
