export const PRODUCT_IMPORT_ROUTES = {
  ROOT: 'product-import',
  CONFIGURE_FEED: 'configure-feed',
  CONFIGURE_SHOP: 'configure-shop',
  MAPPING: 'mapping',
  FILTERING: 'filtering',
};

export const SHOP_STEPS_URL = {
  generateURL: (step: string) => `/${PRODUCT_IMPORT_ROUTES.ROOT}/${step}`,
};
