import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { HttpStatusCodes } from '@models/enum/http-status-codes.enum';
import { HttpErrorType } from '@models/interfaces/http-message.interface';

import { httpErrorMessageMapper } from '@utils/http-message-mapper';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private router: Router) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const routeArray = this.router.url.split('/').slice(1, -1);

    // The new calculate endpoint retuirns a 400 status code when the
    // validation fails so we need to skip the error handling for this endpoint
    // If we need to do this for more endpoints we can improve this code later
    if (request.url.endsWith('calculate')) {
      return next.handle(request);
    }

    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        const error: HttpErrorType = {
          ...err,
          message: httpErrorMessageMapper(err.status),
        };

        if (err.status === HttpStatusCodes.BadRequest) {
          this.router.navigate([...routeArray]);
        }

        return throwError(() => error);
      })
    );
  }
}
