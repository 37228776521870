import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { PricemonitorUserCompany } from '@Patagona/pricemonitor-internal-typescript-angular-13';

import { catchError, filter, map, Observable } from 'rxjs';

import { AppState } from '@store/app.state';
import { selectPortals } from '@store/user';

import { getMappedCompanyList } from '@utils/company-helpers/get-mapped-company-list';

import { ICompanyService } from './company-mapping.interface.service';

@Injectable({
  providedIn: 'root',
})
export class CompanyService implements ICompanyService {
  constructor(private readonly store: Store<AppState>) {}

  public getAllCompanies(
    companies: PricemonitorUserCompany[]
  ): Observable<PricemonitorUserCompany[]> {
    return this.store.select(selectPortals).pipe(
      filter(Boolean),
      map((portals) => getMappedCompanyList(portals, companies)),
      catchError((error) => {
        throw new Error(error);
      })
    );
  }
}
