import { ApiActions } from '@store/api-actions';

import { UserDetailsState } from './details.reducers';

export enum UserDetailsActions {
  USER_DETAILS = '[User Details] Set user details ',
}

export const userDetailsAction = new ApiActions<{}, { details: UserDetailsState }>(
  UserDetailsActions.USER_DETAILS
);

export const setGainsightUserInfoAction = new ApiActions<{}, {}>(
  '[Gainsight] Set Gainsight user info  '
);
