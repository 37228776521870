import { createAction, props } from '@ngrx/store';

import { HeaderState } from './legacy-header.reducers';

export enum HeaderActions {
  // Preferences actions
  SET_HEADER_TITLE_SUCCESS = 'SET_HEADER_TITLE_SUCCESS',
  SET_HEADER_TITLE_FAILED = 'SET_HEADER_TITLE_FAILED',
}

export const SetHeaderSuccessAction = createAction(
  HeaderActions.SET_HEADER_TITLE_SUCCESS,
  props<{ payload: HeaderState }>()
);

export const SetHeaderFailedAction = createAction(HeaderActions.SET_HEADER_TITLE_FAILED);
