import { createReducer, on } from '@ngrx/store';

import { ICompany } from '@app/models/interfaces/company.interface';
import { contractSwitchAction } from '@app/store/router';

import { activeCompanyAction, defaultCompanyAction } from './preferences.actions';

export interface UserPreferencesState {
  activeCompany: ICompany | undefined;
  defaultCompany: ICompany | undefined;
  isSwitchingContracts?: boolean;
}

export const preferencesFeatureKey = 'preferences';

const initialPreferenceState: UserPreferencesState = {
  activeCompany: undefined,
  defaultCompany: undefined,
  isSwitchingContracts: undefined,
};

export const userPreferenceReducer = createReducer(
  initialPreferenceState,
  on(defaultCompanyAction.success, (state, { defaultCompany }) => ({
    ...(state || initialPreferenceState),
    defaultCompany,
  })),
  on(activeCompanyAction.success, (state, { activeCompany }) => ({
    ...(state || initialPreferenceState),
    activeCompany,
  })),
  on(contractSwitchAction.init, (state, _) => ({
    ...(state || initialPreferenceState),
    isSwitchingContracts: true,
  })),
  on(contractSwitchAction.success, (state, _) => ({
    ...(state || initialPreferenceState),
    isSwitchingContracts: false,
  }))
);
