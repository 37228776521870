import { createSelector } from '@ngrx/store';

import { AppState } from '@store/app.state';
import { HeaderState } from '@store/legacy';

const selectHeaderFeature = ({ header }: AppState) => header;

export const selectHeaderTitle = createSelector(
  selectHeaderFeature,
  ({ title }: HeaderState) => title
);
