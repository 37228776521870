import { createSelector } from '@ngrx/store';

import { AppState } from '@store/app.state';

import { Shop } from '@models/interfaces/shops.interface';

import { ProductImportState } from './product-import.reducers';

const selectProductImportFeature = ({ productImport }: AppState) => productImport;

export const selectShop = createSelector(
  selectProductImportFeature,
  (productImportState: ProductImportState) => (productImportState?.shop as Shop) ?? undefined
);

export const selectGroupId = createSelector(
  selectProductImportFeature,
  ({ groupId }: ProductImportState) => groupId ?? null
);

export const selectGroupDisplayName = createSelector(
  selectProductImportFeature,
  ({ groupDisplayName }: ProductImportState) => groupDisplayName
);
