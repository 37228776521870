import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { concatLatestFrom } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { EMPTY, Observable, skipWhile, switchMap, take } from 'rxjs';

import { AppState } from '@app/store/app.state';
import { selectContractSidByPortalName, selectFrontendAPILoggingData } from '@app/store/user';
import { AppInsightsService } from '@app/tracing/application-insights/app-insights.service';
import { isFeApiCall } from '@app/utils/helpers/is-frontend-api-call';

@Injectable()
export class BlockFeApiMismatchInterceptor implements HttpInterceptor {
  constructor(
    protected readonly store: Store<AppState>,
    protected readonly appInsightsService: AppInsightsService
  ) {}

  blockAndLogFeApiCall(
    next: HttpHandler,
    request: HttpRequest<any>,
    portalHeader: string,
    method: string,
    url: string
  ): Observable<never> | Observable<HttpEvent<any>> {
    return this.store.select(selectContractSidByPortalName(portalHeader)).pipe(
      concatLatestFrom(() => this.store.select(selectFrontendAPILoggingData)),
      skipWhile(
        ([_, [activePortalName, userId, activePortalCustomerName]]) =>
          !activePortalName || !userId || !activePortalCustomerName
      ),
      take(1),
      switchMap(([sid, [activePortalName, userId, activePortalCustomerName]]) => {
        if (!sid || window.location.pathname.indexOf(sid) < 0) {
          this.appInsightsService.logEvent('FE_API_SID_MISMATCH', {
            portalHeader,
            activePortalName,
            userId,
            activePortalCustomerName,
            method,
            requestUrl: url,
            mappedSid: sid,
            browserUrl: window.location.pathname,
          });
          return EMPTY;
        }
        return next.handle(request);
      })
    );
  }

  protected checkShouldSkipBlocking(url: string): boolean {
    const isFEAPICalls = isFeApiCall(url);
    const isPortalsRequest = url.indexOf('/user/portals') >= 0;
    return !isFEAPICalls || isPortalsRequest;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const { url, headers, method } = request;
    let portalHeader = '';
    try {
      portalHeader = headers.get('Portal') ?? '';
    } catch (error) {
      console.error('Error getting portal header', error);
    }
    const shouldSkipBlocking = this.checkShouldSkipBlocking(url) || !portalHeader;
    if (shouldSkipBlocking) {
      return next.handle(request);
    }
    return this.blockAndLogFeApiCall(next, request, portalHeader, method, url);
  }
}
