import { PricemonitorGetContractSettingsResponseV1 } from '@Patagona/pricemonitor-internal-typescript-angular-13';

import { ApiActions } from '@store/api-actions';

export enum ContractsActions {
  GET_SETTINGS = '[Contract Settings] Fetch contract settings ',
}

export const contractSettingsAction = new ApiActions<
  {},
  { settings: PricemonitorGetContractSettingsResponseV1 }
>(ContractsActions.GET_SETTINGS);
