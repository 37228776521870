import { HttpMessages } from '@models/enum/http-messages.enum';
import { HttpStatusCodes } from '@models/enum/http-status-codes.enum';

type HttpStatusCodeName = keyof typeof HttpStatusCodes;
type HttpMessageName = keyof typeof HttpMessages;

export function httpErrorMessageMapper(err: number): string {
  const mappedErrorCodes = Object.values(HttpStatusCodes).filter((x) => typeof x === 'number');

  if (!mappedErrorCodes.includes(err)) {
    return HttpMessages.Generic;
  }

  const errByName =
    Object.keys(HttpStatusCodes)[
      Object.values(HttpStatusCodes).indexOf(err as unknown as HttpStatusCodeName)
    ];

  return HttpMessages[errByName as HttpMessageName];
}
