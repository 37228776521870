import { ErrorHandler, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AppInsightsService {
  private readonly angularPlugin = new AngularPlugin();

  private readonly appInsights: ApplicationInsights = new ApplicationInsights({
    config: {
      connectionString: environment.features.applicationInsights.connectionString,
      enableDebug: true,
      extensions: [this.angularPlugin],
      extensionConfig: {
        [this.angularPlugin.identifier]: {
          router: this.router,
          errorServices: [new ErrorHandler()],
        },
      },
    },
  });

  constructor(private readonly router: Router) {
    this.appInsights.loadAppInsights();
    this.loadTelemetryInitializer();
  }

  loadTelemetryInitializer() {
    this.appInsights.addTelemetryInitializer((envelope: any) => {
      envelope.data = envelope.data ?? {};
      envelope.data.environment = environment.features.applicationInsights.envName;
      envelope.data.tabSessionId = sessionStorage.getItem('TabSessionId');
    });
    this.appInsights.trackPageView();
  }

  logEvent(name: string, properties: { [key: string]: any }) {
    this.appInsights.trackEvent({
      name,
      properties,
    });
  }
}
