import { PricemonitorUserCompany } from '@Patagona/pricemonitor-internal-typescript-angular-13';

export const getCompanyAndContractAtIndex = (
  companies: PricemonitorUserCompany[],
  index: number
) => {
  return companies?.map((company: any) => ({
    ...company,
    contracts: [company.contracts[index]],
  }))[index];
};
