import { Directive, OnDestroy } from '@angular/core';

import { Subject } from 'rxjs';

/**
 * @deprecated use Angular's built-in `DestroyRef` and `takeUntilDestroyed`
 *
 * This class is used to:
 * - manage Observable subscriptions in components, where it unsubscribes from obervables
 * to avoid any memory leakage casued by incompleted subscriptions.
 *
 * How to use?
 * For Observables Subscriptions:
 * - Each component that includes an observable subscription should extend SubscriptionManagementBaseClass
 * - Each .subscription should be proceeded with pipe(takeUnitl(this.componentDestroyed))
 * - If the subscription is already procedded with a pipe, then takeUnitl(this.componentDestroyed) should be the last operator
 *
 * @remark @Directive() is needed because the class will be extended by components.
 * Source: https://angular.io/guide/migration-undecorated-classes
 */

@Directive()
export abstract class SubscriptionManagementBaseClass implements OnDestroy {
  componentDestroyed = new Subject<void>();

  ngOnDestroy(): void {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
  }
}
