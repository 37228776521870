import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { Observable, switchMap, take, throwError } from 'rxjs';

import { HttpMessages } from '@app/models/enum/http-messages.enum';
import { AppState } from '@app/store/app.state';
import { selectUserId } from '@app/store/user';
import { AppInsightsService } from '@app/tracing/application-insights/app-insights.service';
import { getCurrentSid } from '@app/utils/helpers/get-current-sid';

import { environment } from '@environments/environment';

@Injectable()
export class BlockGetWithSidMismatchInterceptor implements HttpInterceptor {
  constructor(
    private readonly store: Store<AppState>,
    private readonly appInsightsService: AppInsightsService
  ) {}

  private allowedPaths = [
    `/api/account`,
    `/user/portals`,
    `/user/organizationsPortals`,
    '/looker/attributes',
    '/api/2/companies',
    '/controlpanel/',
    '/api/v3/companies',
  ];

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const { url, method } = request;
    const { pricemonitorApiBaseUrl } = environment;
    const requestPathName = new URL(url).pathname;
    const readingMethod = ['GET'];
    const isReadingRequest = readingMethod.includes(method);
    const isRequestToPricemonitorApi = url.indexOf(pricemonitorApiBaseUrl) > -1;
    const isAllowed = this.allowedPaths.find((path) => requestPathName.indexOf(path) > -1);
    if (!isReadingRequest || !isRequestToPricemonitorApi || isAllowed) {
      return next.handle(request);
    }
    return this.store.select(selectUserId).pipe(
      take(1),
      switchMap((userId) => {
        const currentSid = getCurrentSid(window.location);

        const shouldSkip = currentSid && url.indexOf(currentSid as string) < 0;

        if (shouldSkip) {
          const traceFields = {
            userId,
            url,
            method,
          };
          this.appInsightsService.logEvent('SID mismatch in GET request', traceFields);
          return throwError(() => ({
            error: 'SID mismatch in GET request',
            status: 403,
            statusText: HttpMessages.Forbidden,
            ...traceFields,
          }));
        }

        return next.handle(request);
      })
    );
  }
}
