import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { map, Observable, take } from 'rxjs';

import { OmniaAuthService } from '@services/authentication/omnia-auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(private readonly omniaAuthService: OmniaAuthService) {}

  public canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    // User is Authenticated on both APIs Omnia & Pricemonitor (valid token + and slient one with retrieved user data)
    // User data from source of truth Pricemonitor is retrived
    return this.isUserAuthenticated().pipe(
      map((isAuthenticated) => {
        if (!isAuthenticated) {
          this.omniaAuthService.login(state.url);
          return false;
        }
        return true;
      })
    );
  }

  private isUserAuthenticated(): Observable<boolean> {
    return this.omniaAuthService.isAuthenticated().pipe(take(1));
  }
}
