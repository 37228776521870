import { ApiActions } from '@store/api-actions';

export enum LookerActions {
  GET_ATTRIBUTES = '[Looker] Get looker attributes ',
}

export const getLookerAttributesAction = new ApiActions<
  {},
  { contractSid: string; contractId: string }
>(LookerActions.GET_ATTRIBUTES);
