import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';

import { catchError, delay, map, Observable, of, switchMap, take } from 'rxjs';

import { ShopsService } from '@services/shops/shops.service';

import { legacyApiCallsAction } from '@store/legacy';
import {
  cancelShopRun,
  getShopStatus,
  loadShopsAndStatus,
  triggerShopRun,
} from '@store/product-import';

@Injectable()
export class ProductImportEffects {
  constructor(private readonly shopService: ShopsService, private readonly actions$: Actions) {}

  public readonly getShopsAndStatus$ = createEffect(() => this.getShopsAndStatus());

  public readonly runShop$ = createEffect(() => this.runShop());

  public readonly cancelShopRun$ = createEffect(() => this.cancelShopRun());

  public readonly getShopStatus$ = createEffect(() => this.getShopStatus());

  public readonly getShopStatusAfterRunShop$ = createEffect(() => this.getShopStatusAfterRunShop());

  private getShopsAndStatus(): Observable<Action> {
    return this.actions$.pipe(
      ofType(legacyApiCallsAction.success),
      map(({ shop }) => loadShopsAndStatus.success({ shop })),
      catchError(() => of(loadShopsAndStatus.failed()))
    );
  }

  private runShop(): Observable<Action> {
    return this.actions$.pipe(
      ofType(triggerShopRun.init),
      switchMap((action) =>
        this.shopService.runShop(action.shopName).pipe(
          map(() => triggerShopRun.success({ shopName: action.shopName })),
          catchError(() => of(triggerShopRun.failed()))
        )
      )
    );
  }

  /**
   * After the success of run shop or success of cancel shop,
   * shop status needs to be updated
   * @remark delay of 5sec is added to show cancelling state as in Omnia-portal
   */
  private getShopStatusAfterRunShop(): Observable<Action> {
    return this.actions$.pipe(
      ofType(triggerShopRun.success, cancelShopRun.success),
      delay(5000),
      switchMap((action) => of(getShopStatus.init({ shopName: action.shopName })))
    );
  }

  /**
   * Shop status is coupled with the shop name, therefore
   * this effect updates the entire shop and not only the status property
   */
  private getShopStatus(): Observable<Action> {
    return this.actions$.pipe(
      ofType(getShopStatus.init),
      switchMap(({ shopName }) =>
        this.shopService.getShopStatus(shopName).pipe(
          take(3),
          map((status) => ({ name: shopName, status })),
          map((shop) => getShopStatus.success({ shop })),
          catchError(() => of(getShopStatus.failed()))
        )
      )
    );
  }

  private cancelShopRun(): Observable<Action> {
    return this.actions$.pipe(
      ofType(cancelShopRun.init),
      switchMap((action) =>
        this.shopService.cancelShop(action.shopName).pipe(
          map(() => cancelShopRun.success({ shopName: action.shopName })),
          catchError(() => of(cancelShopRun.failed()))
        )
      )
    );
  }
}
