export enum HttpStatusCodes {
  Success = 200,
  BadRequest = 400,
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  MethodNotAllowed = 405,
  InternalServerError = 500,
  NotImplemented = 501,
  BadGateway = 502,
  Unavailable = 503,
  GatewayTimeout = 504,
  HttpVersionUnsupported = 505,
}
