/**
 * Meta reducers are reducers that run before the main reducer.
 * This meta reducer is used to reset all states (not whitlisted) of application when the user switches contracts.
 * Eg.: on contract witch we want to reset the pricing strategy state, but not the user state as user should still a live through session.
 */

import { ActionReducer } from '@ngrx/store';

import { PriceRecommendationExportState } from './store/price-recommendation-export';
import { PricingStrategyState } from './store/pricing-strategy';
import { ProductImportState } from './store/product-import';
import { RouterActionsEnum } from './store/router/router.actions';

/**
 * The list of states that WON'T be reseted on contract switch on contract switch.
 * - Whenever a new state is added and WON'T be reseted on contract switch, it should be added to this list.
 */
const whitelistedStates = [
  'user',
  'router',
  'snackbar',
  'productImport',
  'priceRecommendationExport',
];
type State = PricingStrategyState | ProductImportState | PriceRecommendationExportState;

export function resetStateMetaReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state, action) => {
    if (action.type === RouterActionsEnum.CONTRACT_SWITCH + 'INIT') {
      const newState: { [key: string]: State | undefined } = {};
      for (const key in state) {
        if (state.hasOwnProperty(key) && !whitelistedStates.includes(key)) {
          newState[key] = undefined;
        }
      }
      return reducer({ ...state, ...newState }, action);
    }
    return reducer(state, action);
  };
}
