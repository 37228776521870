type InputObjectType = { [key: string]: any };

// This helper takes an object and makes sure that all the keys are lowercased,
// without spaces and without + - _ characters
export function objectKeysToLowerCase(inputObject: InputObjectType): object {
  if (typeof inputObject !== 'object' || !Object.keys(inputObject).length) return inputObject;
  if (Array.isArray(inputObject)) return inputObject.map(objectKeysToLowerCase);

  const allowedCharsRegex = /[-+_ ]/gi;

  return Object.keys(inputObject).reduce((outputObject: InputObjectType, key) => {
    const value = inputObject[key as keyof InputObjectType];
    const outputValue =
      typeof value === 'object' && value !== null ? objectKeysToLowerCase(value) : value;

    outputObject[key.toLowerCase().replace(allowedCharsRegex, '')] = outputValue;
    return outputObject;
  }, {});
}
