import { NgModule, Type } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '@guards/auth.guard';

import { AppContainerModule } from '@features/app-container/app-container.module';

import { INACTIVE_ACCOUNT, INVALID_ACCOUNT } from './constants/account-error.constants';
import { AccountErrorModule } from './features/account-error/account-error.module';
import { ROUTES } from './routes/routes.constants';

const routes: Routes = [
  {
    path: ROUTES.APP.ROOT,
    canActivate: [AuthGuard],
    loadChildren: (): Promise<Type<AppContainerModule>> =>
      import('@features/app-container/app-container.module').then(
        (module) => module.AppContainerModule
      ),
  },
  {
    path: ROUTES.INACTIVE_ACCOUNT,
    canActivate: [AuthGuard],
    pathMatch: 'full',
    data: INACTIVE_ACCOUNT,
    loadChildren: (): Promise<Type<AccountErrorModule>> =>
      import('@features/account-error/account-error.module').then(
        (module) => module.AccountErrorModule
      ),
  },
  {
    path: ROUTES.INVALID_ACCOUNT,
    canActivate: [AuthGuard],
    pathMatch: 'full',
    data: INVALID_ACCOUNT,

    loadChildren: (): Promise<Type<AccountErrorModule>> =>
      import('@features/account-error/account-error.module').then(
        (module) => module.AccountErrorModule
      ),
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: ROUTES.APP.ROOT,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      paramsInheritanceStrategy: 'always',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
