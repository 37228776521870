import { Injectable } from '@angular/core';
import { type Auth0Client, createAuth0Client } from '@auth0/auth0-spa-js';
import {
  AccountService,
  PricemonitorUserInfo,
} from '@Patagona/pricemonitor-internal-typescript-angular-13';

import { from, Observable, throwError } from 'rxjs';
import { catchError, concatMap, mergeMap, shareReplay, tap } from 'rxjs/operators';

import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PricemonitorAuthService {
  private readonly pricemonitorProps = environment.features.pricemonitorSingleSignOn.properties;

  // Create an observable of Auth0 instance of client
  private readonly auth0Client$ = (
    from(
      createAuth0Client({
        domain: this.pricemonitorProps.domain,
        clientId: this.pricemonitorProps.clientId,
        authorizationParams: {
          audience: this.pricemonitorProps.audience,
          redirect_uri: this.pricemonitorProps.redirectUri,
        },
      })
    ) as Observable<Auth0Client>
  ).pipe(
    shareReplay(1), // Every subscription receives the same shared value
    catchError((err) => throwError(() => err))
  );

  constructor(private readonly accountService: AccountService) {}

  public readonly isAuthenticated$: Observable<boolean> = this.auth0Client$.pipe(
    concatMap((client: Auth0Client) => from(client.isAuthenticated()))
  );

  public login(): void {
    this.auth0Client$.subscribe((client: Auth0Client) => client.loginWithRedirect());
  }

  public logout(): void {
    this.auth0Client$.subscribe((client: Auth0Client) => client.logout());
  }

  public getUserToken(): Observable<string> {
    return this.auth0Client$.pipe(
      mergeMap((client: Auth0Client) => {
        return from(client.getTokenSilently());
      }),
      tap((token) => token)
    );
  }

  public getUserDetails(): Observable<PricemonitorUserInfo> {
    return this.accountService.userInfo();
  }
}
