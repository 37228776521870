import { createReducer, on } from '@ngrx/store';
import { PricemonitorUserInfo } from '@Patagona/pricemonitor-internal-typescript-angular-13';

import { WithUndefined } from '@models/interfaces/with-undefined.type';

import { userDetailsAction } from './details.actions';

export interface UserDetailsState extends WithUndefined<Omit<PricemonitorUserInfo, 'companies'>> {}

export const userDetailsFeatureKey = 'details';

export const initialUserDetailsState: UserDetailsState = {
  name: undefined,
  lastActiveDate: undefined,
  email: undefined,
  id: undefined,
  roles: undefined,
};

export const userDetailsReducer = createReducer(
  initialUserDetailsState,
  on(userDetailsAction.success, (state, { userDetails }) => ({
    ...(state || initialUserDetailsState),
    ...userDetails,
  })),
  on(userDetailsAction.init, userDetailsAction.failed, () => ({
    ...initialUserDetailsState,
  }))
);
