import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

import { Observable } from 'rxjs';

import { environment } from '@environments/environment';

import { AppState } from '@store/app.state';

import { SubscriptionManagementBaseClass } from '@utils/subscription-management/subscription-management-base-class';

@Injectable()
export class BackendTracing extends SubscriptionManagementBaseClass implements HttpInterceptor {
  constructor(private readonly store: Store<AppState>, private readonly router: Router) {
    super();
  }

  private addTracingHeaders(request: HttpRequest<any>): HttpRequest<any> {
    const sessionId = sessionStorage.getItem('TabSessionId') ?? '';
    return request.clone({
      setHeaders: {
        'x-omnia2ui-tab-id': sessionId!,
        'x-omnia2ui-full-referrer': window.location.pathname,
      },
    });
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const needsTraceHeaders = request.url.indexOf(environment.pricemonitorApiBaseUrl) > -1;
    if (needsTraceHeaders) {
      return next.handle(this.addTracingHeaders(request));
    }

    return next.handle(request);
  }
}
