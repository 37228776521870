import { createReducer, on } from '@ngrx/store';

import { SetHeaderSuccessAction } from './legacy-header.actions';

export interface HeaderState {
  title: string;
}

const initialHeaderState: HeaderState = {
  title: '',
};

export const headerReducer = createReducer(
  initialHeaderState,
  on(SetHeaderSuccessAction, (state, { payload }) => ({
    ...(state || initialHeaderState),
    ...payload,
  }))
);
