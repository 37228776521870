import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';

import { catchError, filter, map, Observable, of, take, withLatestFrom } from 'rxjs';

import { ProductOverviewPath } from '@app/models/const/product-overview-paths';
import { APP_ROUTES } from '@app/routes/routes.constants';

import { EXPORTS_ROUTES } from '@routes/exports-routes.constants';
import { PRODUCT_IMPORT_ROUTES } from '@routes/product-import-routes.constants';

import { AppState } from '@store/app.state';
import { SnackbarWarningAction } from '@store/snackbar';
import {
  activeCompanyAction,
  companiesAction,
  selectCompanies,
  selectDefaultCompany,
  userDetailsAction,
} from '@store/user';

import { getCompanyByContractId } from '@utils/company-helpers/get-company-by-contract-id';
import { urlContainsRoute } from '@utils/helpers/url-contains-route';

import { portalActions } from '../user/portals/portals.actions';

import {
  contractSwitchAction,
  dashboardRedirectAction,
  inactiveAccountRedirectAction,
  invalidAccountRedirectAction,
  redirectToLegacyRootViewAction,
  redirectToPricingStrategyAction,
} from './router.actions';
import { selectRouteParams } from './router.selectors';

@Injectable()
export class RouterEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly router: Router,
    private readonly store: Store<AppState>
  ) {}

  public readonly inactiveAccount$ = createEffect(() => this.inactiveAccount());

  public readonly invalidAccount$ = createEffect(() => this.invalidAccount());

  public readonly noActiveContract$ = createEffect(() => this.noActiveContract());

  public readonly handleContractSwitching$ = createEffect(() => this.handleContractSwitching());

  public readonly redirectToDashboard$ = createEffect(() => this.redirectToDashboard());

  public readonly redirectToProductOverview$ = createEffect(() => this.redirectToProductOverview());

  public readonly redirectToDashboardFromShopDetails$ = createEffect(() =>
    this.redirectToDashboardFromShopDetails()
  );

  public readonly redirectToPricingStrategy$ = createEffect(() => this.redirectToPricingStrategy());

  private inactiveAccount(): Observable<Action> {
    return this.actions$.pipe(
      ofType(userDetailsAction.failed),
      map(() => {
        this.router.navigate(['inactive-account'], { replaceUrl: true });
        return inactiveAccountRedirectAction();
      })
    );
  }

  private invalidAccount(): Observable<Action> {
    return this.actions$.pipe(
      ofType(companiesAction.failed, portalActions.failed),
      map(() => {
        this.router.navigate(['invalid-account'], { replaceUrl: true });
        return invalidAccountRedirectAction();
      })
    );
  }

  private noActiveContract(): Observable<Action> {
    return this.actions$.pipe(
      ofType(activeCompanyAction.failed),
      withLatestFrom(this.store.select(selectDefaultCompany)),
      take(1),
      map(([, defaultContract]) => {
        this.store.dispatch(
          SnackbarWarningAction({
            message: 'Unable to find the requested URL. You have been re-directed.',
          })
        );
        this.router.navigate([`/contract/${defaultContract?.contracts[0].sid}/dashboard`]);

        return activeCompanyAction.init(defaultContract);
      })
    );
  }

  private handleContractSwitching(): Observable<Action> {
    return this.actions$.pipe(
      ofType(contractSwitchAction.init),
      withLatestFrom(this.store.select(selectCompanies)),
      map(([{ currentSid, newSid }, companies]) => {
        const newActiveCompany = getCompanyByContractId(companies, newSid);
        // If the user is on a legacy module, redirect him to the overview/landing view of the same module
        // Exclude PRICEWATCH as it is sub route of /exports but independent page that no need to redirect to overview page
        if (
          urlContainsRoute(this.router.url, PRODUCT_IMPORT_ROUTES) ||
          (urlContainsRoute(this.router.url, EXPORTS_ROUTES) &&
            !this.router.url.split('/').includes(EXPORTS_ROUTES.PRICEWATCH))
        ) {
          const route = this.router.url
            .substring(0, this.router.url.lastIndexOf('/'))
            .replace(currentSid, newSid);
          this.router
            .navigateByUrl('/', { skipLocationChange: true })
            .then(() => this.router.navigate([route]));
          this.store.dispatch(redirectToLegacyRootViewAction.success());
        } else {
          // If not, replace the previous sid with new sid in the url
          const route = this.router.url.replace(currentSid, newSid);
          // remove query params, otherwise route wont be found
          const routeWithouttQueryParams = route.split('?')[0];
          this.router
            .navigateByUrl('/', { skipLocationChange: true })
            .then(() => this.router.navigate([routeWithouttQueryParams]));
        }
        this.store.dispatch(activeCompanyAction.init(newActiveCompany));
        return contractSwitchAction.success({ newSid });
      }),
      catchError((error) => {
        return of(contractSwitchAction.failed(error));
      })
    );
  }

  // Once the user logs in he'll need to be redirected to /contract/{sid}/dashboard
  private redirectToDashboard(): Observable<Action> {
    return this.actions$.pipe(
      ofType(activeCompanyAction.success),
      withLatestFrom(this.store.select(selectRouteParams)),
      map(([{ activeCompany }, routeParams]) => {
        let sid = '';
        if (!routeParams['id']) {
          sid = activeCompany.contracts[0].sid;
          this.router.navigate([`/contract/${sid}/dashboard`]);
        }
        return dashboardRedirectAction.success({ sid });
      }),
      catchError(() => of(dashboardRedirectAction.failed()))
    );
  }

  private redirectToProductOverview(): Observable<Action> {
    return this.actions$.pipe(
      ofType(contractSwitchAction.init),
      filter(() => {
        const { url } = this.router;
        return url.includes(ProductOverviewPath.PRODUCTS);
      }),
      map(({ newSid }) => {
        this.router.navigate([`contract/${newSid}/${ProductOverviewPath.PRODUCT_OVERVIEW}`], {
          replaceUrl: true,
        });
        return redirectToLegacyRootViewAction.success();
      }),
      catchError(() => of(redirectToLegacyRootViewAction.failed()))
    );
  }

  /**
   * Redirect to pricing-strategy page when switching a contract while in the pricing-strategy-explain-why page
   */
  private redirectToPricingStrategy(): Observable<Action> {
    return this.actions$.pipe(
      ofType(contractSwitchAction.init),
      filter(() => {
        const { url } = this.router;
        return url.includes(APP_ROUTES.PRICING_STRATEGY_EXPLAIN_WHY);
      }),
      map(({ newSid }) => {
        this.router.navigate([`contract/${newSid}/${APP_ROUTES.PRICING_STRATEGY}`], {
          replaceUrl: true,
        });
        return redirectToPricingStrategyAction.success();
      }),
      catchError(() => of(redirectToPricingStrategyAction.failed()))
    );
  }

  private redirectToDashboardFromShopDetails() {
    return this.actions$.pipe(
      ofType(contractSwitchAction.init),
      filter(() => {
        const { url } = this.router;
        return url.includes('shops');
      }),
      map(({ newSid }) => {
        this.router.navigate([`/contract/${newSid}/dashboard`], { replaceUrl: true });
        return redirectToLegacyRootViewAction.success();
      }),
      catchError(() => of(redirectToLegacyRootViewAction.failed()))
    );
  }
}
