import { createReducer, on } from '@ngrx/store';
import { PricemonitorGetContractSettingsResponseV1 } from '@Patagona/pricemonitor-internal-typescript-angular-13';

import { contractSettingsAction } from '@store/user';

export const contractsFeatureKey = 'contracts';

export interface ContractsState {
  settings?: PricemonitorGetContractSettingsResponseV1;
}

export const initialContractsState: ContractsState = {
  settings: undefined,
};

export const contractsReducer = createReducer(
  initialContractsState,
  on(contractSettingsAction.success, (state, { settings }) => ({
    ...(state || initialContractsState),
    settings,
  })),
  on(contractSettingsAction.init, contractSettingsAction.failed, () => ({
    ...initialContractsState,
  }))
);
