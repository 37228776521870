import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { Observable, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { TOKEN_EXPIRES_IN } from '@app/config/authentication.config';

import { AppState } from '@store/app.state';
import { tokenRenewalAction } from '@store/user';

import { HttpStatusCodes } from '@models/enum/http-status-codes.enum';

/* This interceptors job is to treat a 401. If the token is expired, it will fetch a new one
 * and retry.
 */
@Injectable()
export class InvalidTokenInterceptor implements HttpInterceptor {
  constructor(private readonly store: Store<AppState>) {}

  // Upon entering the app, an expiration date needs to be set
  private expirationDate: Date = new Date(new Date().getTime() + TOKEN_EXPIRES_IN);

  // But it also needs a method to set a new expiration date if previous token expired
  private setTokenExpiresIn(): void {
    const currentDate = new Date();
    const currentTime = currentDate.getTime();

    this.expirationDate = new Date(currentTime + TOKEN_EXPIRES_IN);
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const currentDate = new Date();

    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        /* If 401, and the time when the request was made is newer than the expiration date,
         * fetch a new token and retry
         */
        if (err.status === HttpStatusCodes.Unauthorized && currentDate >= this.expirationDate) {
          this.setTokenExpiresIn();
          return of(this.store.dispatch(tokenRenewalAction.init())).pipe(
            switchMap(() => next.handle(request))
          );
        }
        return throwError(() => err);
      })
    ) as Observable<HttpEvent<unknown>>;
  }
}
