import { ApiActions } from '@store/api-actions';

import { CompaniesState } from './companies.reducers';

export enum CompaniesActions {
  SET_COMPANY_DATA = '[Companies] Set companies ',
}

export const companiesAction = new ApiActions<{}, { data: CompaniesState }>(
  CompaniesActions.SET_COMPANY_DATA
);
