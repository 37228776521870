import { createSelector } from '@ngrx/store';

import { AppState } from '@app/store/app.state';

import { IContract } from '@models/interfaces/company.interface';

import { selectUserId } from '../details/details.selectors';

const selectUser = ({ user }: AppState) => user;

const selectPreferencesFeatureKey = createSelector(selectUser, ({ preferences }) => preferences);

export const selectDefaultCompany = createSelector(
  selectPreferencesFeatureKey,
  ({ defaultCompany }) => defaultCompany
);

export const selectDefaultContract = createSelector(
  selectDefaultCompany,
  (defaultCompany) => defaultCompany?.contracts[0] as IContract
);

export const selectActiveCompany = createSelector(
  selectPreferencesFeatureKey,
  ({ activeCompany }) => activeCompany
);

export const selectActiveCompanyName = createSelector(
  selectActiveCompany,
  (activeCompany) => activeCompany?.name ?? ''
);

export const selectActiveCompanyContracts = createSelector(
  selectActiveCompany,
  (activeCompany) => activeCompany?.contracts
);

export const selectActiveContract = createSelector(
  selectPreferencesFeatureKey,
  ({ activeCompany }) => activeCompany?.contracts[0] as IContract
);

export const selectActiveContractSid = createSelector(
  selectActiveContract,
  (activeContract) => activeContract?.sid
);

export const selectActivePortalName = createSelector(
  selectActiveContract,
  (activeContract) => activeContract?.portalName
);
export const selectActivePortalCustomerName = createSelector(
  selectActiveCompany,
  (activeCompany) => activeCompany?.contracts[0].customerAdpsName
);
export const selectIsSwitchingContract = createSelector(
  selectPreferencesFeatureKey,
  ({ isSwitchingContracts }) => isSwitchingContracts
);

export const selectFrontendAPILoggingData = createSelector(
  selectActivePortalName,
  selectUserId,
  selectActivePortalCustomerName,
  (activePortalName, userId, activePortalCustomerName) => {
    return [activePortalName, userId, activePortalCustomerName];
  }
);
