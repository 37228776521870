import { createAction, props } from '@ngrx/store';

/**
 * ApiActions generic classes are used to optimize the process of creating actions for API calls
 */

/**
 * ApiActions represents all actions that trigger API request(s) and update the store once.
 * This kind of actions needs only success/fail states
 */

// @TODO: revisit types and implementations: https://omniaretail.atlassian.net/browse/PH-452
// Any types could be generics
// eslint-disable-next-line
export class ApiActions<Input extends object, Output extends object> {
  init: any; // represents the action that is responsible for makeing the API call

  inProgress: any; // represents the in progress state

  success: any; // represents the action that is dispatched after API call is successful

  failed: any; // represents the action that is dispatched after API call has failed

  aborted: any; // represents the action that was cancelled by other factors

  constructor(name: string) {
    this.init = createAction(name + 'INIT', props<{ Input: any }>());
    this.inProgress = createAction(name + 'IN PROGRESS');
    this.success = createAction(name + 'SUCCESS', props<{ Output: any }>());
    this.failed = createAction(name + 'FAIL', props<{ error: string }>());
    this.aborted = createAction(name + 'ABORTED');
  }
}
