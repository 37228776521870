import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';

import { catchError, EMPTY, map, mergeMap, Observable } from 'rxjs';

import { PortalService } from '@services/portal/portal.service';

import { AppState } from '@store/app.state';
import { portalActions } from '@store/user';

import { PortalInterface } from '@models/interfaces/portals.interface';

@Injectable()
export class UserPortalsEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<AppState>,
    private readonly portalService: PortalService
  ) {}

  public readonly setUserPortals$ = createEffect(() => this.setUserPortals());

  private setUserPortals(): Observable<Action> {
    return this.actions$.pipe(
      ofType(portalActions.init),
      mergeMap(() => this.getUserPortals()),
      map((portals) => portalActions.success(portals)),
      catchError(() => {
        this.store.dispatch(portalActions.failed());
        return EMPTY;
      })
    );
  }

  private getUserPortals(): Observable<PortalInterface[]> {
    return this.portalService.getPortals();
  }
}
