import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

import { Observable, skipWhile, switchMap, take } from 'rxjs';

import { isFeApiCall } from '@app/utils/helpers/is-frontend-api-call';
import { isNonClientPMApiCall } from '@app/utils/helpers/is-pm-api-call';

import { AppState } from '@store/app.state';
import { selectOmniaToken, selectPmToken } from '@store/user';

import { SubscriptionManagementBaseClass } from '@utils/subscription-management/subscription-management-base-class';

@Injectable()
export class AuthInterceptor extends SubscriptionManagementBaseClass implements HttpInterceptor {
  constructor(private readonly store: Store<AppState>, private readonly router: Router) {
    super();
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const needsAuthToken = isFeApiCall(request.url);
    if (needsAuthToken) {
      return this.store.select(selectOmniaToken).pipe(
        skipWhile((token) => !token),
        take(1),
        switchMap((token) =>
          next.handle(request.clone({ setHeaders: { Authorization: `Bearer ${token}` } }))
        )
      );
    }
    // Append PM token PM API calls that are not represented in the api client library
    if (isNonClientPMApiCall(request.url)) {
      return this.store.select(selectPmToken).pipe(
        skipWhile((token) => !token),
        take(1),
        switchMap((token) =>
          next.handle(request.clone({ setHeaders: { Authorization: `Bearer ${token}` } }))
        )
      );
    }

    return next.handle(request);
  }
}
