import { ApiActions } from '@store/api-actions';

import { AuthState } from './authentication.reducers';

export enum AuthenticationActions {
  // Omnia Login actions
  AUTH = '[Auth] User authentication ',
  USER_TOKEN = '[Login] User Token Retrieval ',
  OMNIA_USER_TOKEN = '[Login] Omnia User Token Retrieval ',
  TOKEN_RENEWAL = '[Login] Token Renewal ',
}

export const authAction = new ApiActions<{}, { payload: AuthState }>(AuthenticationActions.AUTH);

export const userTokenAction = new ApiActions<{}, { omniaToken: string }>(
  AuthenticationActions.USER_TOKEN
);
export const omniaUserTokenAction = new ApiActions<{}, { token: string }>(
  AuthenticationActions.OMNIA_USER_TOKEN
);

export const tokenRenewalAction = new ApiActions<{}, {}>(AuthenticationActions.TOKEN_RENEWAL);
