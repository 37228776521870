import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';

import { EMPTY, Observable, switchMap } from 'rxjs';

import { LoaderService } from '@app/services/loader/loader.service';

import { loadingSpinnerHide, loadingSpinnerShow } from './loader-spinner.actions';

@Injectable()
export class LoadingSpinnerEffects {
  public readonly showSpinner$ = createEffect(() => this.showSpinner());

  public readonly hideSpinner$ = createEffect(() => this.hideSpinner());

  constructor(private readonly actions$: Actions, private readonly loaderService: LoaderService) {}

  private showSpinner(): Observable<Action> {
    return this.actions$.pipe(
      ofType(loadingSpinnerShow),
      switchMap(() => {
        this.loaderService.show();
        return EMPTY;
      })
    );
  }

  private hideSpinner(): Observable<Action> {
    return this.actions$.pipe(
      ofType(loadingSpinnerHide),
      switchMap(() => {
        this.loaderService.hide();
        return EMPTY;
      })
    );
  }
}
