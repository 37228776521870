import { createAction, props } from '@ngrx/store';

import { ApiActions } from '@store/api-actions';

import { Shop } from '@models/interfaces/shops.interface';

export enum ProductImportActions {
  // Feed Groups actions
  SET_GROUP_ID = '[feed-groups] Set group id',
  SET_GROUP_DISPLAY_NAME = '[feed-groups] Set goupr display name',
}

/**
 * Feed Groups actions
 */
export const setGroupId = createAction(
  ProductImportActions.SET_GROUP_ID,
  props<{ groupId: string }>()
);
export const setGroupDisplayName = createAction(
  ProductImportActions.SET_GROUP_DISPLAY_NAME,
  props<{ groupDisplayName: string }>()
);

/**
 * Shop actions
 */
export const loadShopsAndStatus = new ApiActions<{}, { shops: Shop[] }>('[Shops] Load shops ');

export const triggerShopRun = new ApiActions<{ shopName: string }, { shopName: string }>(
  '[Shops] Run shop '
);

export const cancelShopRun = new ApiActions<{ shopName: string }, { shopName: string }>(
  '[Shops] Cancel shop-run '
);

export const getShopStatus = new ApiActions<{ shopName: string }, { shop: Shop }>(
  '[Shops] Get shop status '
);
