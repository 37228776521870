import { createAction } from '@ngrx/store';

import { ApiActions } from '../api-actions';

export enum RouterActionsEnum {
  NAVIGATE = '[Router] Navigation ',
  CONTRACT_SWITCH = '[Router] Contract switching navigation ',
  DASHBOARD_REDIRECT = '[Router] Dashboard redirect ',
  PRICING_STRATEGY_REDIRECT = '[Router] Pricing strategy redirect ',
  OVERVIEW_REDIRECT = '[Router] Overview redirect ',
  INACTIVE_ACCOUNT_REDIRECT = '[Router] /inactive-account redirect',
  INVALID_ACCOUNT_REDIRECT = '[Router] /invalid-account redirect',
}

export const contractSwitchAction = new ApiActions<{}, {}>(RouterActionsEnum.CONTRACT_SWITCH);
export const dashboardRedirectAction = new ApiActions<{}, { sid: string }>(
  RouterActionsEnum.DASHBOARD_REDIRECT
);

export const inactiveAccountRedirectAction = createAction(
  RouterActionsEnum.INACTIVE_ACCOUNT_REDIRECT
);
export const invalidAccountRedirectAction = createAction(
  RouterActionsEnum.INVALID_ACCOUNT_REDIRECT
);

export const redirectToLegacyRootViewAction = new ApiActions<{}, {}>(
  RouterActionsEnum.OVERVIEW_REDIRECT
);
export const redirectToPricingStrategyAction = new ApiActions<{}, {}>(
  RouterActionsEnum.PRICING_STRATEGY_REDIRECT
);
