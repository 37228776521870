import { createReducer, on } from '@ngrx/store';

import { SnackbarConfig } from '@components/snackbar/snackbar/snackbar.interface';

import {
  SnackbarDefaultAction,
  SnackbarErrorAction,
  SnackbarInfoAction,
  SnackbarSuccessAction,
  SnackbarWarningAction,
} from './snackbar.actions';

const initialSnackbarState: SnackbarConfig = {
  message: '',
};

export const snackbarReducer = createReducer(
  initialSnackbarState,
  on(SnackbarSuccessAction, (state, payload: SnackbarConfig) => ({
    ...(state || initialSnackbarState),
    ...payload,
  })),
  on(SnackbarErrorAction, (state, payload: SnackbarConfig) => ({
    ...(state || initialSnackbarState),
    ...payload,
  })),
  on(SnackbarDefaultAction, (state, payload: SnackbarConfig) => ({
    ...(state || initialSnackbarState),
    ...payload,
  })),
  on(SnackbarInfoAction, (state, payload: SnackbarConfig) => ({
    ...(state || initialSnackbarState),
    ...payload,
  })),
  on(SnackbarWarningAction, (state, payload: SnackbarConfig) => ({
    ...(state || initialSnackbarState),
    ...payload,
  }))
);
