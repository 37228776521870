import { EXPORTS_ROUTES } from './exports-routes.constants';
import { PRODUCT_IMPORT_ROUTES } from './product-import-routes.constants';
import { PRODUCT_OVERVIEW_ROUTES } from './product-overview-routes.constants';
export const APP_ROUTES = {
  ROOT: 'contract',
  DASHBOARD: 'dashboard',
  PRODUCT_OVERVIEW: PRODUCT_OVERVIEW_ROUTES,
  PRICE_RECOMMENDATIONS: 'price-recommendations',
  PRODUCTS: 'products',
  SHOPS: 'shops',
  PRICE_COMPARISON: 'price-comparison',
  PRICING_STRATEGY: 'pricing-strategy',
  PRICING_STRATEGY_EXPLAIN_WHY: 'pricing-strategy-explain-why',
  PRODUCT_IMPORT: PRODUCT_IMPORT_ROUTES,
  EXPORTS: EXPORTS_ROUTES,
  FILTERS: 'filters',
  MARKET_EXPLORER: 'market-explorer',
  ORGANIZATION: 'organization',
};

export const ROUTES = {
  APP: APP_ROUTES,
  INACTIVE_ACCOUNT: 'inactive-account',
  INVALID_ACCOUNT: 'invalid-account',
};
