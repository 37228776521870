import { createReducer, on } from '@ngrx/store';

import { PortalInterface } from '@models/interfaces/portals.interface';

import { portalActions } from './portals.actions';

export interface PortalState extends Array<PortalInterface> {}

export const portalsFeatureKey = 'portals';

const initialPortalState: PortalState = [];

export const portalsReducer = createReducer(
  initialPortalState,
  on(portalActions.success, (state, portals) => ({
    ...(state || initialPortalState),
    ...portals,
  }))
);
