import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { firstValueFrom } from 'rxjs';

import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MarketingService {
  public configuration = environment;

  public headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });

  constructor(private http: HttpClient) {}

  getTags(data: any) {
    return firstValueFrom(
      this.http.post(
        environment.bffLegacyEndpoint + '/feedmanager/channel/gettags',
        this.provideBody(data),
        { headers: this.headers }
      )
    );
  }

  getAllChannels(data: any) {
    return firstValueFrom(
      this.http.post(
        environment.bffLegacyEndpoint + '/feedmanager/channel/getallchannels',
        this.provideBody(data),
        { headers: this.headers }
      )
    );
  }

  getGlobalApis(data: any) {
    return firstValueFrom(
      this.http.post(
        environment.bffLegacyEndpoint + '/feedmanager/Config/getglobalapis',
        this.provideBody(data),
        { headers: this.headers }
      )
    );
  }

  setApi(data: any) {
    return firstValueFrom(
      this.http.post(
        environment.bffLegacyEndpoint + '/feedmanager/channel/setapi',
        this.provideBody(data),
        { headers: this.headers }
      )
    );
  }

  getAdwordsCampaigns(data: any) {
    return firstValueFrom(
      this.http.post(
        environment.bffLegacyEndpoint + '/feedmanager/config/getadwordscampaigns',
        this.provideBody(data),
        { headers: this.headers }
      )
    );
  }

  getCostSetting(data: { name: string }) {
    return firstValueFrom(
      this.http.post(
        environment.bffLegacyEndpoint + '/feedmanager/channel/getcostsetting',
        this.provideBody(data),
        { headers: this.headers }
      )
    );
  }

  setCostSetting(data: any) {
    return firstValueFrom(
      this.http.post(
        environment.bffLegacyEndpoint + '/feedmanager/channel/setcostsetting',
        this.provideBody(data),
        { headers: this.headers }
      )
    );
  }

  pauseAll(data: any) {
    return firstValueFrom(
      this.http.post(
        environment.bffLegacyEndpoint + '/feedmanager/marktplaats/pauseall',
        this.provideBody(data),
        { headers: this.headers }
      )
    );
  }

  loadChannelExport(data: any) {
    return firstValueFrom(
      this.http.post(
        environment.bffLegacyEndpoint + '/feedmanager/Config/Get',
        this.provideBody(data),
        { headers: this.headers }
      )
    );
  }

  updateStatus(data: any) {
    return firstValueFrom(
      this.http.post(
        environment.bffLegacyEndpoint + '/feedmanager/status/get',
        this.provideBody(data),
        {
          headers: this.headers,
        }
      )
    );
  }

  requestEnqueue(data: any) {
    return firstValueFrom(
      this.http.post(
        environment.bffLegacyEndpoint + '/feedmanager/channel/runexport',
        this.provideBody(data),
        { headers: this.headers }
      )
    );
  }

  requestStatusDetails(data: any) {
    return firstValueFrom(
      this.http.post(
        environment.bffLegacyEndpoint + '/feedmanager/channel/cancelexport',
        this.provideBody(data),
        { headers: this.headers }
      )
    );
  }

  requestCancel(data: any) {
    return firstValueFrom(
      this.http.post(
        environment.bffLegacyEndpoint + '/feedmanager/channel/cancelexport',
        this.provideBody(data),
        { headers: this.headers }
      )
    );
  }

  deleteChannel(data: any) {
    return firstValueFrom(
      this.http.post(
        environment.bffLegacyEndpoint + '/feedmanager/channel/del',
        this.provideBody(data),
        {
          headers: this.headers,
        }
      )
    );
  }

  changeName(data: any) {
    return firstValueFrom(
      this.http.post(
        environment.bffLegacyEndpoint + '/feedmanager/channel/changename',
        this.provideBody(data),
        { headers: this.headers }
      )
    );
  }

  jobStatusReports(data: any) {
    return firstValueFrom(
      this.http.post(
        environment.bffLegacyEndpoint + '/feedmanager/status/job_status_reports',
        this.provideBody(data),
        { headers: this.headers }
      )
    );
  }

  setTags(data: any) {
    return firstValueFrom(
      this.http.post(
        environment.bffLegacyEndpoint + '/feedmanager/channel/settags',
        this.provideBody(data),
        { headers: this.headers }
      )
    );
  }

  evaluate(data: any) {
    return firstValueFrom(
      this.http.post(
        environment.bffLegacyEndpoint + '/feedmanager/feed/evaluate',
        this.provideBody(data),
        { headers: this.headers }
      )
    );
  }

  getBidsSetting(data: any) {
    return firstValueFrom(
      this.http.post(
        environment.bffLegacyEndpoint + '/feedmanager/channel/getbidssetting',
        this.provideBody(data),
        { headers: this.headers }
      )
    );
  }

  getApis(data: any) {
    return firstValueFrom(
      this.http.post(
        environment.bffLegacyEndpoint + '/feedmanager/config/getapis',
        this.provideBody(data),
        {
          headers: this.headers,
        }
      )
    );
  }

  getApi(data: any) {
    return firstValueFrom(
      this.http.post(
        environment.bffLegacyEndpoint + '/feedmanager/channel/getapi',
        this.provideBody(data),
        {
          headers: this.headers,
        }
      )
    );
  }

  getApisTypeInfo(data: any) {
    return firstValueFrom(
      this.http.post(
        environment.bffLegacyEndpoint + '/feedmanager/config/getapistypeinfo',
        this.provideBody(data),
        { headers: this.headers }
      )
    );
  }

  setBidsSetting(data: any) {
    return firstValueFrom(
      this.http.post(
        environment.bffLegacyEndpoint + '/feedmanager/channel/setbidssetting',
        this.provideBody(data),
        { headers: this.headers }
      )
    );
  }

  getMarketingCpcCards(data: any) {
    return firstValueFrom(
      this.http.post(
        environment.bffLegacyEndpoint + '/feedmanager/config/get_marketing_cpc_cards',
        this.provideBody(data),
        { headers: this.headers }
      )
    );
  }

  apiConnectedLoad(data: any) {
    return firstValueFrom(
      this.http.post(
        environment.bffLegacyEndpoint + '/feedmanager/config/getallapis',
        this.provideBody(data),
        { headers: this.headers }
      )
    );
  }

  getFunctions() {
    return firstValueFrom(
      this.http.get(environment.bffLegacyEndpoint + '/feedmanager/feed/getfunctions')
    );
  }

  getFunctionStore(): any {
    return firstValueFrom(
      this.http.get(environment.bffLegacyEndpoint + '/feedmanager/feed/getfunctionstore')
    );
  }

  private provideBody(data: any) {
    return Object.keys(data)
      .map((key) => {
        return [key, JSON.stringify(data[key])].map(encodeURIComponent).join('=');
      })
      .join('&');
  }
}
