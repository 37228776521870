import {
  PricemonitorUserCompany,
  PricemonitorUserContract,
} from '@Patagona/pricemonitor-internal-typescript-angular-13';

export const getCompanyByContractId = (companiesList: PricemonitorUserCompany[], id: string) => {
  return Object.values(companiesList)
    .map((company: PricemonitorUserCompany) => {
      const filteredContracts = company.contracts?.find(
        (contract: PricemonitorUserContract) => contract.sid === id
      );
      return (
        filteredContracts && {
          ...company,
          contracts: [filteredContracts],
        }
      );
    })
    .filter((arr) => arr !== undefined)[0];
};
