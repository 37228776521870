import { Configuration } from '@Patagona/pricemonitor-internal-typescript-angular-13';

import { environment } from '@environments/environment';

import { UserTokenService } from '@services/authentication/user-token.service';

const basePath = environment.pricemonitorApiBaseUrl;

export const PMConfigFactory = (tokenService: UserTokenService): Configuration => {
  const config = new Configuration({
    basePath,
  });
  tokenService.userToken$.subscribe((res: string) => (config.accessToken = res));
  return config;
};
