import { PricemonitorUserCompany } from '@Patagona/pricemonitor-internal-typescript-angular-13';

import { ICompany } from '@app/models/interfaces/company.interface';

import { PortalInterface } from '@models/interfaces/portals.interface';

export const getMappedCompanyList = (
  portals: PortalInterface[],
  pricemonitorCompanies: PricemonitorUserCompany[] = []
): ICompany[] => {
  const mappedPortals = Object.values(portals);
  const findPortalsBySid = (sid: string) => {
    return mappedPortals.find(({ customerName }) => customerName === `omnia_genesis_${sid}`);
  };
  return (
    Object.values(pricemonitorCompanies)
      // return only the company-contract pairs that have an equivalent organization-portal mapping
      .map((company) => ({
        ...company,
        // Filter the contracts who's sid exist in the `customerName` of portals array
        contracts: company.contracts
          .filter(({ sid }) => findPortalsBySid(sid))
          // Append portalName to the contract object
          .map((contract) => ({
            ...contract,
            portalName: findPortalsBySid(contract.sid)?.portalName,
            customerAdpsName: findPortalsBySid(contract.sid)?.customerName,
          })),
      }))
      // remove the companies that have no contract-portal mapping
      .filter((contract) => contract.contracts.length > 0)
  );
};
