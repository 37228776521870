import { createReducer, on } from '@ngrx/store';

import { omniaUserTokenAction, userTokenAction } from './authentication.actions';

type User = import('@auth0/auth0-spa-js').User;

export interface AuthState extends User {
  token?: string;
  omniaToken?: string;
}

export const authenticationFeatureKey = 'authentication';

export const initialAuthState: AuthState = {
  token: undefined,
  omniaToken: undefined,
};

export const auhthenticationReducer = createReducer(
  initialAuthState,
  on(userTokenAction.success, (state, { token }) => ({
    ...(state || initialAuthState),
    token,
  })),
  on(omniaUserTokenAction.success, (state, { omniaToken }) => ({
    ...(state || initialAuthState),
    omniaToken,
  }))
);
