import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { firstValueFrom } from 'rxjs';

import { environment } from '@environments/environment';

import { IMappingService } from './legacy-mapping.interface.service';

@Injectable({
  providedIn: 'root',
})
export class MappingService implements IMappingService {
  public headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });

  constructor(private http: HttpClient) {}

  public getTargetColumn(data = {}): Promise<any> {
    return firstValueFrom(
      this.http.post(
        environment.bffLegacyEndpoint + '/feedmanager/Feed/GetTargetColumn',
        this.provideBody(data),
        {
          headers: this.headers,
        }
      )
    );
  }

  public getChannelTargetColumn(data?: any): Promise<any> {
    return firstValueFrom(
      this.http.post(
        environment.bffLegacyEndpoint + '/feedmanager/channel/GetTargetColumn',
        this.provideBody(data),
        { headers: this.headers }
      )
    );
  }

  public deleteMapping(data: any) {
    return firstValueFrom(
      this.http.post(
        environment.bffLegacyEndpoint + '/feedmanager/channel/delmapping',
        this.provideBody(data),
        {
          headers: this.headers,
        }
      )
    );
  }

  provideQuery(params: any) {
    return Object.keys(params)
      .map((key) => {
        return [key, params[key]].map(encodeURIComponent).join('=');
      })
      .join('&');
  }

  provideBody(data: any = {}) {
    return Object.keys(data)
      .map((key) => {
        return [key, JSON.stringify(data[key])].map(encodeURIComponent).join('=');
      })
      .join('&');
  }
}
