import { createAction, props } from '@ngrx/store';

import { ScrollElementState } from './legacy-scroll-element.model';

export enum ScrollActions {
  // Preferences actions
  SCROLL_ELEMENT_INTO_VIEW_INIT = 'SCROLL_ELEMENT_INTO_VIEW_INIT',
  SCROLL_ELEMENT_INTO_VIEW_SUCCESS = 'SCROLL_ELEMENT_INTO_VIEW_SUCCESS',
  SCROLL_ELEMENT_INTO_VIEW_FAILED = 'SCROLL_ELEMENT_INTO_VIEW_FAILED',
}

export const ScrollElementIntoViewInitAction = createAction(
  ScrollActions.SCROLL_ELEMENT_INTO_VIEW_INIT,
  props<{ payload: ScrollElementState }>()
);

export const ScrollElementIntoViewSuccessAction = createAction(
  ScrollActions.SCROLL_ELEMENT_INTO_VIEW_SUCCESS
);

export const ScrollElementIntoViewFailedAction = createAction(
  ScrollActions.SCROLL_ELEMENT_INTO_VIEW_FAILED
);
