import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from '@environments/environment';

import {
  StatusReportModelRequestBodyInterface,
  StatusReportSuccessResponseInterface,
} from '@models/legacy/models/legacy-job-status-report.model';

import { IStatusService } from './legacy-status.interface.service';

@Injectable({
  providedIn: 'root',
})
export class StatusService implements IStatusService {
  private readonly apiSegment = 'Status';

  constructor(private readonly http: HttpClient) {}

  public getJobStatusReports$(
    data: StatusReportModelRequestBodyInterface
  ): Observable<StatusReportSuccessResponseInterface> {
    return this.http.post<StatusReportSuccessResponseInterface>(
      `${environment.bffApiEndpoint}/${this.apiSegment}/JobStatusReports`,
      data
    );
  }
}
