import { Injectable } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import type { User } from '@auth0/auth0-spa-js';

import { Observable } from 'rxjs';
import { skipWhile, switchMap, take, takeUntil } from 'rxjs/operators';

import { SubscriptionManagementBaseClass } from '@utils/subscription-management/subscription-management-base-class';

@Injectable({
  providedIn: 'root',
})
export class OmniaAuthService extends SubscriptionManagementBaseClass {
  constructor(private readonly auth: AuthService) {
    super();
  }

  public isAuthenticated(): Observable<boolean> {
    return this.auth.isLoading$.pipe(
      skipWhile((loading) => loading),
      switchMap(() => this.auth.isAuthenticated$)
    );
  }

  public login(stateUrl: string): void {
    this.auth
      .loginWithRedirect({ appState: { target: stateUrl } })
      .pipe(take(1), takeUntil(this.componentDestroyed))
      .subscribe();
  }

  public logout(): Observable<void> {
    return this.auth.logout({ logoutParams: { returnTo: `https://${window.location.host}` } });
  }

  public getUserToken(): Observable<string> {
    return this.auth.getAccessTokenSilently();
  }

  public getUserDetails(): Observable<User | null | undefined> {
    return this.auth.user$;
  }

  public hasLicense(license: string): boolean {
    if (localStorage.getItem('omnia-lic')) {
      return localStorage.getItem('omnia-lic')?.indexOf(license) !== -1;
    }

    return false;
  }
}
