import { ICompany } from '@app/models/interfaces/company.interface';

import { ApiActions } from '@store/api-actions';

export enum UserPreferencesActions {
  SET_DEFAULT_COMPANY = '[Preferences] Default company and contract ',
  SET_ACTIVE_COMPANY = '[Preferences] Active company and contract ',
}

export const defaultCompanyAction = new ApiActions<{}, { data: ICompany }>(
  UserPreferencesActions.SET_DEFAULT_COMPANY
);

export const activeCompanyAction = new ApiActions<{}, { data: ICompany }>(
  UserPreferencesActions.SET_ACTIVE_COMPANY
);
