import { Injectable } from '@angular/core';
import {
  PricemonitorUserCompany,
  PricemonitorUserContract,
} from '@Patagona/pricemonitor-internal-typescript-angular-13';

import { UserDetailsState } from '@store/user';

declare const aptrinsic: (
  arg0: string,
  userFields: {
    id: number;
    email: string;
    firstName: string;
    lastName: string;
    contractID: string;
  },
  accountFields: { id: number; name: string }
) => void;

@Injectable({ providedIn: 'root' })
export class GainsightService {
  // For Gainsight users tracking dashboard need to pass user info on successful login
  public identify(
    userInfo: UserDetailsState,
    userCompany: PricemonitorUserCompany,
    contract: PricemonitorUserContract
  ): void {
    try {
      if (userInfo.id && userInfo.email && userInfo.name) {
        // Passing user and account objects:
        aptrinsic(
          'identify',
          {
            // User Fields
            id: userInfo.id, // Required for logged in app users
            email: userInfo.email,
            firstName: userInfo.name,
            lastName: userInfo.name,
            // Flat custom fields
            contractID: contract.sid,
          },
          {
            // Account Fields
            id: userCompany.id,
            name: userCompany.name,
          }
        );
      }
    } catch (e) {
      console.error(' Failed to pass user info to gainsight', e);
    }
  }
}
