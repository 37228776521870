export const EXPORTS_ROUTES = {
  ROOT: 'exports',
  REPORTS: 'exports/reports',
  OVERVIEW: 'overview', // Outdated route, needed only for redirects
  MAPPING: 'mapping',
  FILTERING: 'filtering',
  TOPX: 'top-x',
  EXPORT: 'export',
  PREVIEW: 'preview',
  PRICEWATCH: 'pricewatch-export',
  SCHEDULES: 'schedules',
};

export const EXPORT_STEPS_URL = {
  generateURL: (step: string) => `/${EXPORTS_ROUTES.REPORTS}/${step}`,
};
