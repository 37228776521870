import { Injectable } from '@angular/core';
import { Actions, createEffect, EffectNotification, ofType, OnRunEffects } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';

import {
  catchError,
  debounceTime,
  EMPTY,
  exhaustMap,
  finalize,
  map,
  Observable,
  of,
  switchMap,
  takeUntil,
} from 'rxjs';

import { LoaderService } from '@services/loader/loader.service';
import { PortalService } from '@services/portal/portal.service';
import { ShopsService } from '@services/shops/shops.service';

import { AppState } from '@store/app.state';
import { loadShopsAndStatus } from '@store/product-import';
import { SnackbarErrorAction } from '@store/snackbar';
import { activeCompanyAction, selectActivePortalName } from '@store/user';

import {
  legacyApiCallsAction,
  legacyModuleDestroyAction,
  legacyModuleInitAction,
} from './common.actions';

@Injectable()
export class CommonEffects implements OnRunEffects {
  constructor(
    private readonly shopService: ShopsService,
    private readonly actions$: Actions,
    private readonly loaderService: LoaderService,
    private readonly store: Store<AppState>,
    private readonly portalService: PortalService
  ) {}

  /* We need to make sure these effects run only on legacy modules
   * As such, we use ngrxOnRunEffects to make sure these effects run
   * only from the time legacyModuleInitAction is dispatched, til the time
   * legacyModuleDestroyAction is dispatched.
   * These two actions are emitted on ngOnInit and ngOnDestroy of the
   * ProductImportComponent and PriceRecommendationExportComponent
   */

  ngrxOnRunEffects(
    resolvedEffects$: Observable<EffectNotification>
  ): Observable<EffectNotification> {
    return this.actions$.pipe(
      ofType(legacyModuleInitAction),
      exhaustMap(() =>
        resolvedEffects$.pipe(takeUntil(this.actions$.pipe(ofType(legacyModuleDestroyAction))))
      )
    );
  }

  public readonly initLegacyDataFetching$ = createEffect(() => this._initLegacyDataFetching());

  public readonly handleApiFailure$ = createEffect(() => this._handleApiFailure(), {
    dispatch: false,
  });

  private _initLegacyDataFetching(): Observable<Action> {
    return this.actions$.pipe(
      ofType(activeCompanyAction.success, legacyApiCallsAction.init),
      switchMap(() =>
        this.store.select(selectActivePortalName).pipe(
          // debounceTime(0) make sure only the last value is passed through to the switchMap and this after the call stack has been cleared.
          debounceTime(0),
          switchMap((portalName) => {
            this.loaderService.show();
            return portalName ? this.portalService.switchPortal(portalName) : EMPTY;
          })
        )
      ),
      switchMap(() =>
        this.shopService
          .getShops()
          .pipe(catchError((error) => of(legacyApiCallsAction.failed(error))))
      ),
      switchMap((shops) =>
        // The requirments for Genesis is to have one shop per user,
        // therefore, we take the first shop to be rid of the array data structure
        this.shopService.getShopStatus(shops[0].name).pipe(
          map((status) => ({
            name: shops[0].name,
            status: Object.fromEntries(
              Object.entries(status).map(([key, value]) => [key.toLowerCase(), value])
            ),
          })),
          map((shop) => {
            this.store.dispatch(loadShopsAndStatus.success({ shop }));
            return legacyApiCallsAction.success({ shop });
          }),
          catchError((error) => of(legacyApiCallsAction.failed(error)))
        )
      ),
      catchError((error) => of(legacyApiCallsAction.failed(error))),
      finalize(() => this.loaderService.hide())
    );
  }

  private _handleApiFailure(): Observable<void> {
    return this.actions$.pipe(
      ofType(legacyApiCallsAction.failed),
      map(({ message }) => this.store.dispatch(SnackbarErrorAction({ message })))
    );
  }
}
