import { createReducer, on } from '@ngrx/store';

import { ICompany } from '@app/models/interfaces/company.interface';

import { WithUndefined } from '@models/interfaces/with-undefined.type';

import { companiesAction } from './companies.actions';

export interface CompaniesState extends Array<WithUndefined<ICompany>> {}

export const companiesFeatureKey = 'companies';

const initialCompaniesState: CompaniesState = [
  {
    id: undefined,
    name: undefined,
    contracts: undefined,
  },
];

export const companiesReducer = createReducer(
  initialCompaniesState,
  on(companiesAction.success, (state, { companies }) => ({
    ...(state || initialCompaniesState),
    ...companies,
  })),
  on(companiesAction.init, companiesAction.failed, () => ({
    ...initialCompaniesState,
  }))
);
